<template>
  <div class="myInquiryListItem" :class="{ isen: IS_EN }">
    <div class="discard" v-if="info.status == 4">
      <span :class="{ isEn: IS_EN }">{{ $t("discard") }}</span>
    </div>
    <el-row class="item_header clearfix">
      <el-col :span="12" class="fl textOverflow">
        <!-- 询盘单号 -->
        <span class="inquiryNumber">
          {{ $t("inquiryNumber") }}{{ $t("colon") }}
          <a @click="goToDetail" class="mainColor">{{
            info.inquiry_number | textFormat
          }}</a>
        </span>
        <!-- 品名 -->
        <span  class="inquiryNumber">
          {{$t('commodity')}}{{ $t("colon")}}
          <i class="infoColor">{{ info.cargo_name | textFormat }}</i>
        </span>
        <!--  打包方式 -->
        <span>
          {{$t('Packaging')}}{{ $t("colon") }}
          <i class="infoColor">{{ info.pack_type | packedFormat(LOCALE)  }}</i>
        </span>
      </el-col>
      <!-- 负责人 -->
      <el-col :span="12" style="text-align:right">
        <div>
          {{ $t("conscientiousPeople") }}{{ $t("colon") }}
          <i class="infoColor">{{info.name | textFormat}}</i>
        </div>
      </el-col>
    </el-row>
    <!-- -------------------------------------------------------------------- -->
    <div class="item_content">
      <div class="alwaysShow clearfix">
        <el-row class="elRowWrap">
          <el-col :span="8" >
            <div class="addressInfo">
              <img
                :src="info.from_country | countryFlagFormat"
                alt
                class="flagImg"
                :title="info.from_country | countryFormat(LOCALE)"
              />
              <span
                class="cityName textOverflow"
                :title="
                  info.from_region_en | priorFormat(info.from_region_zh, LOCALE)
                "
                >{{
                  info.from_region_en | priorFormat(info.from_region_zh, LOCALE)
                }}</span
              >
              <img
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/arrivalArrow.png"
                alt
                class="arrivalArrow"
              />
              <img
                :src="info.arrival_country | countryFlagFormat"
                alt
                class="flagImg"
                :title="info.arrival_country | countryFormat(LOCALE)"
              />
              <span
                class="cityName textOverflow"
                :title="
                  info.arrival_region_en
                    | priorFormat(info.arrival_region_zh, LOCALE)
                "
                >{{
                  info.arrival_region_en
                    | priorFormat(info.arrival_region_zh, LOCALE)
                }}</span
              >
            </div>
          </el-col>
          <el-col :span="4">
            <div class="way_company">
              <div>
                <img :src=" 1|shipModeImgFormat" alt="">
                {{ 1|shipModeFormat }}
              </div>
            </div>
          </el-col>
          <el-col :span="6" style="text-align:center">
              <el-button type="text" class="mainColor" @click="to_companyDetai">{{info.name_en | priorFormat(info.name_zh ,LOCALE)}}</el-button>
           </el-col>
          <el-col :span="6">
            <div class="inquiry_handles">
              <div v-if="info.status === 0">
                <!-- 发布 -->
                <el-popover placement="top" width="200" v-model="sendVisible">
                  <p class="confirmTip tc">{{ $t("publishInquiry") }}？</p>
                  <div class="tr">
                    <el-button
                      size="mini"
                      type="text"
                      @click="sendVisible = false"
                      >{{ $t("cancel") }}</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="sendInquiry(info)"
                      :loading="sendLoading"
                      >{{ $t("confirm") }}</el-button
                    >
                  </div>
                  <el-button type="text" slot="reference">{{
                    $t("publish")
                  }}</el-button>
                </el-popover>
                <el-divider direction="vertical"></el-divider>
                <!-- 删除 -->
                <el-popover placement="top" width="200" v-model="delVisible">
                  <p class="confirmTip tc">{{ $t("deleteInquiry") }}？</p>
                  <div class="tr">
                    <el-button
                      size="mini"
                      type="text"
                      @click="delVisible = false"
                      >{{ $t("cancel") }}</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      :loading="delLoading"
                      @click="delInquiry(info)"
                      >{{ $t("confirm") }}</el-button
                    >
                  </div>
                  <el-button type="text"  slot="reference">{{
                    $t("delete")
                  }}</el-button>
                </el-popover>
                <el-divider direction="vertical"></el-divider>
                <!-- 编辑 -->
                <el-button @click="goToInquiryEdit" type="text" class="mianColor">{{
                    $t("edit")
                  }}</el-button>
              </div>
              <div v-else>
                  <!-- 详情 -->
                  <el-button
                    class="cursor textColor"
                    type="text"
                    @click="goToDetail"
                    >{{ $t("Detail") }}
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                  <!-- 评价 -->
                  <el-button
                    class="cursor textColor"
                    type="text"
                    @click="openEvaluationDialog(info)"
                    >{{ info.is_evaluation?$t("viewEvaluation"):$t("appraise") }}
                  </el-button>
                  <el-button type="text"></el-button>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- <div class="trackInfo" v-show="showInfo">
        <el-table :data="tableData">
          <el-table-column prop="company" :label="$t('company')" width="280">
            <template slot-scope="scope">
              <div
                class="textOverflow"
                :title="
                  scope.row.company_name_en
                    | priorFormat(scope.row.company_name_zh, LOCALE)
                "
              >
                <nuxt-link
                  class="mainColor"
                  :to="
                    '/company/companyDetail/about?company_id=' +
                    scope.row.company_id
                  "
                  target="_blank"
                  >{{
                    scope.row.company_name_en
                      | priorFormat(scope.row.company_name_zh, LOCALE)
                  }}</nuxt-link
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" :label="$t('location')" width="180">
            <template slot-scope="scope">
              <div
                class="textOverflow"
                :title="
                  scope.row.company_country
                    | countryCityFormat(
                      scope.row.company_region_en,
                      scope.row.company_region_zh,
                      LOCALE
                    )
                "
              >
                {{
                  scope.row.company_country
                    | countryCityFormat(
                      scope.row.company_region_en,
                      scope.row.company_region_zh,
                      LOCALE
                    )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="people" :label="$t('dockingPerson')">
            <template slot-scope="scope">
              <div
                class="textOverflow"
                :title="
                  scope.row.user_name_en
                    | priorFormat(scope.row.user_name_zh, LOCALE)
                "
              >
                {{
                  scope.row.user_name_en
                    | priorFormat(scope.row.user_name_zh, LOCALE)
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="status" :label="$t('status')">
            <template slot-scope="scope">
              <div
                class="textOverflow"
                :title="scope.row.follow_up_status | inquiryTrackStatus(LOCALE)"
              >
                {{ scope.row.follow_up_status | inquiryTrackStatus(LOCALE) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="type"
            :label="$t('allocationMode')"
            width="160"
          >
            <template slot-scope="scope">
              <div
                class="textOverflow"
                :title="scope.row.distribute_type | distributionMethodFormat"
              >
                {{
                  scope.row.distribute_type | distributionMethodFormat(LOCALE)
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="time"
            :label="$t('allocationTime')"
            width="160"
          >
            <template slot-scope="scope">
              <div :title="scope.row.distribute_time | secondFormat">
                {{ scope.row.distribute_time | secondFormat }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            :label="$t('handle')"
            width="150"
            class="handle"
          >
            <template slot-scope="scope">
              <el-popover
                placement="top"
                width="230"
                v-model="scope.row.orderVisible"
                v-if="showOrderBtn && isNotClose(scope.row.follow_up_status)"
              >
                <p class="confirmTip tc">
                  {{ $t("confirmOrder") }}{{ $t("qmark") }}
                </p>
                <p class="confirmTip tc">{{ $t("cooperativeFreight") }}</p>
                <div class="tr">
                  <el-button
                    size="mini"
                    type="text"
                    @click="scope.row.orderVisible = false"
                    >{{ $t("cancel") }}</el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="order(scope.row)"
                    :loading="orderLoading"
                    >{{ $t("confirm") }}</el-button>
                </div>
                <el-button type="text" slot="reference" class="orderBtn">{{
                  $t("placeAndOrder")
                }}</el-button>
              </el-popover>
              <el-divider
                v-if="!scope.row.is_evaluation && IS_ZH"
                direction="vertical"
              ></el-divider>
              <span>
                <el-button
                  class=""
                  :class="{ isen: IS_EN }"
                  v-if="!scope.row.is_evaluation"
                  @click="openEvaluationDialog(scope.row)"
                  type="text"
                  >{{ $t("appraise") }}</el-button
                >
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div> -->
    </div>
    <!--评价-->
    <el-dialog
      :title="$t('writeEvaluation')"
      :visible.sync="evaluationDialog"
      width="470px"
      @close="pingjiaDialogClose"
    >
      <consignorComment
        :inquiryId="info.inquiry_shipper_id"
        v-if="evaluationDialog"
        :distributeId="currentDistributeId"
        @closeEvaluationDialog="clearEvaluationDialog"
      ></consignorComment>
    </el-dialog>
    <!--查看评价-->
    <el-dialog
      class="view-evaluation"
      :title="$t('viewEvaluation')"
      :visible.sync="viewEvaluationDialog"
      width="760px"
    >
      <template >
        <viewEvaluation
          v-if="viewEvaluationDialog"
          :inquiryId="info.inquiry_shipper_id"
          :type="1"
        ></viewEvaluation>
      </template>
      
    </el-dialog>
  </div>
</template>

<script>
import viewEvaluation from "./viewEvaluation";
import consignorComment from "./consignorComment";
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      tableData: [],
      showInfo: false,
      loadingData: false,
      sendVisible: false,
      sendLoading: false,
      discardVisible: false,
      discardLoading: false,
      delVisible: false,
      delLoading: false,
      getMoreVisible: false,
      getMoreLoading: false,
      orderVisible: false,
      orderLoading: false,
      //当前点击的公司的分拨id
      currentDistributeId: null,
      evaluationDialog: false,
      viewEvaluationDialog: false,
      currentHanleTableData: null,
    };
  },

  mounted() {

  },
  methods: {
    pingjiaDialogClose(){
      this.evaluationDialog=false
    },
    to_companyDetai(){
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query:{
          parameter:this._encode({
            company_id:this.info.creator_company_id
          })
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    getStatusTime(status) {
      let current = this.info.time_list.find((item) => {
        return item.status == status;
      });
      if (current) {
        return current.created_time;
      } else {
        return "";
      }
    },
    async delInquiry(info) {
      this.delLoading = true;
      try {
        let result = await this.$store.dispatch(
          "baseConsole/delInquiry",
          {
            inquiry_id: info.inquiry_shipper_id,
          }
        );
        this.delLoading = false;
        this.delVisible = false;
        if (result.success) {
          this.$delTip();
          this.$emit("flushList");
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.delLoading = false;
        this.$seTip();
      }
    
    },
    // 报关
    async showCustomRecord(info) {
      if (this.showInfo) {
        this.showInfo = false;
        return;
      }
      this.loadingData = true;
      try {
        let result = await this.$store.dispatch(
          "API_inquiryCenter/inquiry_trackingRecord",
          {
            inquiry_customs_id: info.inquiry_customs_id,
            status: info.status,
          }
        );
        if (result.success) {
          let data = result.data;
          this.tableData = data;
        }
        this.loadingData = false;
      } catch (e) {
        this.$seTip();
      }
      this.showInfo = true;
    },
    // 发布
    sendInquiry(info) {
      // this.$log({
      //   type: 1,
      //   topic: "cargo",
      //   id: "cargo.inquiry.release",
      //   content: JSON.stringify({
      //     inquiry_id: this.info.inquiry_shipper_id,
      //   }),
      // });
      this.updataInquiryStatus(info, 1, () => {
        this.info.status = 1;
        this.info.showStatus = 1;
      });
    },
    async updataInquiryStatus(info, status, nv) {
      let currentLoading = "";
      let currentVisible = "";
      switch (status) {
        case 5:
          currentLoading = "getMoreLoading";
          currentVisible = "getMoreVisible";
        case 4:
          currentLoading = "discardLoading";
          currentVisible = "discardVisible";
          break;
        case 1:
          currentLoading = "sendLoading";
          currentVisible = "sendVisible";
          break;
      }
      this[currentLoading] = true;
        try {
          let result = await this.$store.dispatch(
            "baseConsole/inquiry_updataStatus",
            {
              inquiry_id: info.inquiry_shipper_id,
              status: status,
            }
          );
          this[currentLoading] = false;
          this[currentVisible] = false;
          if (result.success) {
            this.$osTip();
            nv && nv();
          } else {
            this.$seTip();
          }
        } catch (e) {
          this.$seTip();
          this.orderLoading = false;
          this.orderVisible = false;
        }
    },
    //打开评价对话框
    openEvaluationDialog(data) {
      this.currentHanleTableData = data;
      this.currentDistributeId = data.distribute_id;
      this.evaluationDialog = true;
    },
    clearEvaluationDialog(flag) {
      this.evaluationDialog = false;
      if (flag) {
        this.currentHanleTableData.is_evaluation = 1;
      }
    },
    openViewEvaluation() {
      this.viewEvaluationDialog = true;
    },
    async order(data) {
      // this.$log({
      //   type: 1,
      //   topic: "cargo",
      //   id: "cargo.inquiry.order",
      //   content: JSON.stringify({
      //     inquiry_id: this.info.inquiry_shipper_id,
      //   }),
      // });
      this.orderLoading = true;
      let params = {
        new_follow_up_status: 7,
        old_follow_up_status: data.follow_up_status,
        user_id: this.USER_ID,
        follow_up_time: this.$moment().unix(),
        language_type: this.IS_EN ? 1 : 2,
        distribute_id: data.distribute_id,
        status: 3,
      };
      try {
          let _params = Object.assign(params, {
            inquiry_id: this.info.inquiry_shipper_id,
          });
          let result = await this.$store.dispatch(
            "API_inquiryCenter/inquiryCenter_userOrder",
            _params
          );
          this.orderLoading = false;
          data.orderVisible = false;
          if (result.success) {
            data.follow_up_status = 7;
            this.info.status = 3;
            this.info.showStatus = 4;
            this.info.time_list.push({
              status: 3,
              created_time: this.$moment().unix(),
            });
            this.$osTip();
          } else {
            this.$seTip();
          }
        } catch (e) {
          this.$seTip();
          this.orderLoading = false;
          data.orderVisible = false;
        }
    },
    goToDetail() {
      this.$router.push({
        path: "/console/directOverseas/inquiryDetail",
        query: {
          parameter: this._encode({
            inquiry_id: this.info.inquiry_shipper_id,
            fillout_type: this.info.publish_type == 1 ? 3 : 1,
          }),
        },
      });
      
    },
    isNotClose(status) {
      if (status == 2 || status == 9 || status == 10 || status == 13) {
        return false;
      } else {
        return true;
      }
    },
    goToInquiryEdit(){
      this.$router.push({
        path: "/publishInquiry",
        query: {
          parameter: this._encode({
            inquiry_id: this.info.inquiry_id,
            fillout_type: 1
          })
        }
      });
    }
  },
  computed: {
    showOrderBtn() {
      let isSuccess = this.tableData.find((item) => {
        return item.follow_up_status == 7;
      });
      if (isSuccess) {
        return false;
      } else {
        return true;
      }
    },
  },
  components: { consignorComment, viewEvaluation },
  watch: {
    $route(nv) {
      this.showInfo = false;
    },
  },
};
</script>

<style scoped lang="less">
.confirmTip {
  margin-bottom: 10px;
  font-size: 14px;
}

.myInquiryListItem {
  padding: 16px 32px 24px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  position: relative;
  &.isen {
    /deep/ .el-button {
      padding: 7px 0;
      display: block;
      width: 100%;
      text-align: right;
    }
    .elRowWrap {
      display: flex;
      display: -webkit-flex;
      align-items: center;
    }
    .discardBtn {
      display: block;
      margin-top: -10px;
    }
    /deep/ .el-button + .el-button {
      margin-left: 0 !important;
    }
  }
  .discard {
    position: absolute;
    width: 50px;
    height: 50px;
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/discardBack.png");
    background-size: 100% 100%;
    top: 0px;
    left: 0px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 50px;

    span {
      display: block;
      transform: rotate(-45deg) translate(0, -15px);

      &.isEn {
        transform: rotate(-45deg) translate(0, -8px);
      }
    }
  }

  .item_header {
    border-bottom: 2px solid #ededed;
    padding-bottom: 16px;
    .inquiryNumber {
      margin-right: 20px;
    }

    // .fl {
    //   width: 930px;
    // }
  }

  .item_content {
    padding-top: 24px;

    .alwaysShow {
      .addressInfo {
        line-height: 31px;
        position: relative;
        top: -7px;
      }

      .inquiryProgress {
        width: 400px;
        margin: 0 auto;
        &.isen {
          width: 440px;
        }
      }

      .inquiry_handles {
        text-align: right;
        line-height: 42px;
        .handleen {
          &.isen {
            display: inline-block;
            width: 285px;
          }
        }

        .el-button {
          font-size: 12px;
        }
      }

      .cityName {
        position: relative;
        top: 12px;
        display: inline-block;
        width: 120px;
        text-align: center;
        font-size: 12px;
      }

      .arrivalArrow {
        margin: 0px 16px;
      }

      .flagImg {
        width: 47px;
        height: 31px;
        margin-right: 8px;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
        cursor: pointer;
      }
    }

    .trackInfo {
      margin-top: 20px;

      /deep/ .el-table .cell {
        font-size: 12px;

        .el-button {
          font-size: 12px;
        }
      }

      /deep/ .el-table td {
        padding: 5px 0px;
      }
    }
  }
  .view-evaluation {
    max-height: 848px;
  }
}
.mainColor {
  cursor: pointer;
}
.handle {
  .el-button {
    &.isen {
      margin-left: 0;
    }
  }
}
.block {
  display: block;
}
.way_company{
  height: 42.5px;
  display: flex;
  align-items: center;
}
</style>
