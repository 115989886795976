<template>
    <div class="d_box" style="background:#F2F2F2">
        <myInquiryListSearch @inquirySearch="inquirySearch"></myInquiryListSearch>
        <div class="inquiry_item_list" v-loading="loading">
            <div
                v-for="(item, index) in tableData"
                :key="index"
                class="inquiry_item"
            >
            <myInquiryListItem
                :info="item"
                @flushList="handleSearch(true)"
            ></myInquiryListItem>
            </div>
            <div v-if="tableData.length == 0 && !loading" class="noData">
                <noDataImg ></noDataImg>
            </div>  
        </div>
        <div class="paginationBlock">
            <el-pagination
            layout="total, prev, pager, next"
            :total="total"
            :current-page.sync="CurrentPage"
            :page-size="limit"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import myInquiryListSearch from '~bac/components/directOverseas/myInquiry/myInquiryListSearch.vue'
import myInquiryListItem from '~bac/components/directOverseas/myInquiry/myInquiryListItem.vue'
export default {
    components:{
        myInquiryListSearch,
        myInquiryListItem
    },
    data() {
        return{
            loading:false,
            total:0,
            limit:4,
            CurrentPage:1,
            tableData:[],
            queryParams: {
                inquiry_number: "",
                cargo_name: "",
                status: "",
                from_region: "",
                arrival_region: "",
                distribute_way: "",
                port:"",
                import_export_way:"",
                transport_mode:""
            },
        }
    },
    mounted(){
        this.getList()
        console.log('------',this.USER_INFO);
    },
    watch:{
        CurrentPage() {
            this.getList();
        },
    },
    methods:{
        inquirySearch(value){
            this.queryParams=value
            this.getList()
        },
        handleSearch(){
            this.getList()
        },
        async getList(){
            this.loading=true
            let params=Object.assign(this.queryParams)
            
            params.limit=this.limit
            params.start=this.CurrentPage-1
            params.inquiry_kind=2 //询盘类型：1-原有询盘，2-直通海外询盘
            if(this.USER_INFO.is_admin){
                params.company_id=125571//this.USER_INFO.company_id 
            }else{
                params.user_id=53397//  this.USER_INFO.id
            }
            
            let res=await this.$store.dispatch('baseConsole/getInquiryList', params);
            this.tableData=res.data
            this.total=res.count
            this.loading=false
            console.log('-----------',res);
        }
    }
}
</script>
<style lang="less" scoped>
    .d_box{
        height: 100%;
    }
    .inquiry_item_list{
        margin-top:15px;
        .inquiry_item{
            margin-top:15px;
        }
    }
    .noData{
        position: relative;
        height: 500px;
    }
</style>