<template>
  <div class="writeComment">
    <p class="clearfix title">
      <span class="fl">{{$t('Anonymous_con')}}</span>
    </p>
    <el-form
      ref="comment"
      :model="commentForm"
      label-width="165px"
      label-position="left"
      :rules="rules"
    >
      <el-form-item :label="$t('Responsespeed')+'：'" prop="response_speed" class="response">
        <el-rate
          :disabled="isEvaluation"
          class="stars"
          v-model="commentForm.response_speed"
        ></el-rate>
      </el-form-item>
      <el-form-item
        :label="$t('matchingdegree')+'：'"
        prop="matching_degree"
        class="matching"
      >
        <el-rate
          :disabled="isEvaluation"
          class="stars"
          v-model="commentForm.matching_degree"
        ></el-rate>
      </el-form-item>
      <el-form-item
        :label="$t('satisfactiondegree')+'：'"
        prop="satisfaction_degree"
        class="satisfaction"
      >
        <el-rate
          :disabled="isEvaluation"
          class="stars"
          v-model="commentForm.satisfaction_degree"
        ></el-rate>
      </el-form-item>
      <el-form-item prop="details" class="details">
        <el-input
          :disabled="isEvaluation"
          type="textarea"
          :rows="3"
          :placeholder="$t('experience')"
          v-model="commentForm.details"
        ></el-input>
      </el-form-item>
      <div class="footerBox clearfix" :class="{'isen':IS_EN}">
        <el-button
          size="small"
          type="primary"
          @click="save('comment')"
          class="fr confirm"
          :loading="saveBtnLoading"
          >{{$t('save')}}</el-button
        >
        <el-button size="small" class="fr" @click="cancel('comment')"
          >{{$t('cancel')}}</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { isObjectAllPropEmpty } from "@/baseUtils";
export default {
  props: ["distributeId","inquiryId"],
  data() {
    return {
      commentForm: {
        response_speed: null,
        matching_degree: null,
        satisfaction_degree: null,
        details: ""
      },
      rules: {
        response_speed: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error(this.$t('changeResponsespeed')));
              } else {
                callback();
              }
            },
            trigger: "change"
          }
        ],
        matching_degree: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error(this.$t('changematchingdegree')));
              } else {
                callback();
              }
            },
            trigger: "change"
          }
        ],
        satisfaction_degree: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error(this.$t('changesatisfactiondegree')));
              } else {
                callback();
              }
            },
            trigger: "change"
          }
        ],
        details: [
          {
            required: true,
            message: this.$t('experience'),
            trigger: "blur"
          }
        ]
      },
      saveBtnLoading: false,
      isEvaluation: false,
      //评论id
      evaluationId: null,
      FollowUpEvaluation: false,
      followUpBtnLoading: false,
      FollowUpEvaluationForm: {
        desc: ""
      },
      FollowUpEvaluationRules: {
        desc: [
          {
            required: true,
            message: this.$t('inputFollowevaluation'),
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getDetail();
  },
  watch: {},
  methods: {
    async getDetail() {
      let params = {};
      params.distribute_id = this.distributeId;
        let result = await this.$store.dispatch(
          "baseConsole/inquiry_searchConsignor",
          params
        );
        if (result.success) {
          let data = result.data;
          if (isObjectAllPropEmpty(data)) {
          } else {
            this.isEvaluation = true;
            this.commentForm.response_speed = data.response_speed;
            this.commentForm.matching_degree = data.matching_degree;
            this.commentForm.satisfaction_degree = data.satisfaction_degree;
            this.commentForm.details = data.details;
            this.evaluationId = data.id;
          }
        }
    },
    save(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.saveFn();
        } else {
          return false;
        }
      });
    },
    //保存评价请求
    async saveFn() {
      // this.$log({
      //   type:1,
      //   topic:"cargo",
      //   id:"cargo.inquiry.evaluation",
      //   content:JSON.stringify({
      //     inquiry_id:this.inquiryId
      //   })
      // })
      this.saveBtnLoading = true;
      let params = {};
      params.distribute_id = this.distributeId;
      params.appraiser_id = this.USER_INFO.id;
      params.response_speed = this.commentForm.response_speed;
      params.matching_degree = this.commentForm.matching_degree;
      params.satisfaction_degree = this.commentForm.satisfaction_degree;
      params.details = this.commentForm.details;
      
        let result = await this.$store.dispatch(
          "baseConsole/inquiry_consignorEvaluation",
          params
        );
        console.log(result);
        if (result.success) {
          this.saveBtnLoading = false;
          this.$emit("closeEvaluationDialog",true);
          this.$ssTip()
        } else {
          this.saveBtnLoading = false;
          this.$seTip();
        }
      
    },
    cancel(formName) {
      this.$refs[formName].resetFields();
      this.$emit("closeEvaluationDialog");
    },
    saveFollow(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.saveFollowFn();
          this.$refs[formName].resetFields();
        } else {
          return false;
        }
      });
    },
  },
  components: {}
};
</script>

<style scoped lang="less">
.writeComment {
  width: 400px;
  /deep/ .el-rate__icon {
  }
  .details {
    margin-top: 10px;
    /deep/ .el-form-item__content {
      margin-left: 0 !important;
    }
  }
  .response,
  .matching,
  .satisfaction {
    /deep/ .el-form-item__error {
      top: 28px;
    }
  }
}
.title {
  line-height: 40px;
}
.stars {
  margin-top: 10px;
}
.el-form-item {
  margin-bottom: 0px;
}
.footerBox {
  margin-top: 20px;
  .confirm {
    margin-left: 20px;
  }
  &.isen{
    .el-button{
      display: inline-block!important;
      width: 100px!important;
      text-align:center!important;
    }
  }
  
}
</style>
