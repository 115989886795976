<template>
  <div class="myInquiryListSearch">
    <el-form
      :label-position="'right'"
      label-width="20px"
      :class="{ 'form-wrap': IS_EN }"
    >
      <div class="clearfix">
        <el-row>
          <el-col :span="6">
            <el-form-item label="">
              <el-input
                :placeholder="$t('inquiryNumber')"
                v-model="queryParams.inquiry_number"
                clearable
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="">
              <el-input
                :placeholder="$t('commodity')"
                v-model="queryParams.cargo_name"
                clearable
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="">
              <el-select
                :placeholder="$t('status')"
                v-model="queryParams.status"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in myInquiryStatus"
                  :key="item.key"
                  :value="item.value"
                  :label="IS_EN ? item.label_en : item.label_zh"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="min-width:203px" class="tr top3" :class="{ updown: IS_ZH }">
            <el-button
              type="default"
              @click="changeOpenStatus(!isOpen)"
              size="small"
              class="openAndClose top1"
              plain
            >
              <i class="el-icon-plus" v-if="!isOpen"></i>
              <i class="el-icon-minus" v-else></i>
            </el-button>
            <el-button type="primary" size="small" @click="search">{{$t('search')}}</el-button>
            <el-button  size="small" @click="Resert">{{$t('reset')}}</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="clearfix" v-show="isOpen">
        <el-row v-if="inquiryType != 2">
          <el-col :span="6">
            <el-form-item label="">
              <CityCompleteWithCountry
                v-model="queryParams.from_region"
                :placeTitle="$t('departure')"
                :clearable="true"
              ></CityCompleteWithCountry>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="">
              <CityCompleteWithCountry
                v-model="queryParams.arrival_region"
                :placeTitle="$t('destination')"
                :clearable="true"
              ></CityCompleteWithCountry>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="">
              <el-select
                v-model="queryParams.distribute_way"
                size="small"
                :placeholder="$t('allocationMode')"
                clearable
              >
                <el-option
                  v-for="item in distributionMethod"
                  :key="item.key"
                  :value="item.value"
                  :label="IS_EN ? item.label_en : item.label_zh"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-else>
          <el-col :span="7">
            <el-form-item label="">
              <el-input
                :placeholder="$t('portTitle')"
                v-model="queryParams.port"
                clearable
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="">
              <el-select
                v-model="queryParams.import_export_way"
                size="small"
                :placeholder="$t('inOutMode')"
                clearable
              >
                <el-option
                  v-for="item in inOrOut"
                  :key="item.key"
                  :value="item.value"
                  :label="IS_EN ? item.label_en : item.label_zh"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="7">
            <el-form-item label="">
              <el-select
                v-model="queryParams.transport_mode"
                size="small"
                :placeholder="$t('shippingMode')"
                clearable
              >
                <el-option
                  v-for="item in transportType"
                  :key="item.key"
                  :value="item.value"
                  :label="IS_EN ? item.label_en : item.label_zh"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  myInquiryStatus,
  distributionMethod,
  inOrOut,
  transportType,
} from "@/baseUtils/enumValue";
import CityCompleteWithCountry from "@/baseComponents/CityCompleteWithCountry";
export default {
  components: { CityCompleteWithCountry, distributionMethod },
  data() {
    return {
      input: "",
      isOpen: false,
      departure: "",
      arrival: "",
      queryParams: {
        inquiry_number: "",
        cargo_name: "",
        status: "",
        from_region: "",
        arrival_region: "",
        distribute_way: "",
        port:"",
        import_export_way:"",
        transport_mode:""
      },
      myInquiryStatus,
      distributionMethod,
      inOrOut,
      transportType,
    };
  },
  props: {
    initParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
    inquiryType: {
      type: Number,
      default: 1,
    },
  },
  created() {
    this.queryParams = Object.assign(this.queryParams, this.initParams);
  },
  methods: {
    Resert(){
      this.queryParams={
        inquiry_number: "",
        cargo_name: "",
        status: "",
        from_region: "",
        arrival_region: "",
        distribute_way: "",
        port:"",
        import_export_way:"",
        transport_mode:""
      }
    },
    search() {
      this.$emit('inquirySearch', this.queryParams);
      // this.$store.commit(
      //   "inquiry/SET_INQUIRY_LIST_SEARCH_HEADER",
      //   this.queryParams
      // );
      // if (this.inquiryType != 2) {
      //   this.$GLOBALEVENT.$emit("MYINQUIRYLISTSEARCH");
      // } else {
      //   this.$GLOBALEVENT.$emit("CUSTOMSDETAIL");
      // }
    },
    changeOpenStatus(status) {
      this.isOpen = status;
    },
  },
};
</script>

<style scoped lang="less">
.myInquiryListSearch {
  padding: 20px 0;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  .form-wrap {
    position: relative;
    left: -10px;
  }

  .el-form-item {
    margin-bottom: 15px;
    width: 330px;
    .el-select {
      width: 100%;
    }
  }
  .updown {
    padding-right: 20px;
  }
}
</style>
